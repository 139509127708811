import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import { Formik, Form, Field, FieldArray, getIn } from 'formik'

import ClientLayout from '../components/client/ClientLayout';
import Card from '../components/card'
import { clientLoad, ClientAddAPIProvider } from '../services/clientAdd';

import FieldText from '../components/field/fText'
import FieldPhone from '../components/field/phone'
import FieldRadios from '../components/field/radioGroup'
import Button from '../components/button'
import Icon from '../components/icon'
import Messages from './../components/messages'

import ProgressBar from '../components/progressBar'
import { defaults } from '../lib/defaults'

import * as Yup from 'yup';

import {schema, fields} from '../utils/contact';

import LocationContext from '../context/location';
import Page from '../components/page';
import DashboardLayout from '../components/dashboard/DashboardLayout';

const FormSchema = Yup.object().shape({
  contacts: Yup.array().of(schema)
});

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return ((touch && error)
        ? (<div className="field__error">{error}</div>)
        : null
      );
    }}
  />
);

const normalizeData = (values, location) => {
  const [primaryContact] = values.contacts;
  const secondaryContacts = values.contacts.slice(1);

  const data = Object.assign(
    {},
    values,
    primaryContact,
    {
      contacts: secondaryContacts.map(contact => ({
        name: `${contact.firstName} ${contact.lastName}`,
        email: contact.email,
        phone: contact.phone,
        preferred: contact.preferred
      }))
    },
    { location }
  );

  return data;
}

const ClientAddPage = () => {
  const location = useContext(LocationContext);
  const next = client => {
    navigate(`/client/${client}/edit/appt/1`);
  }

  const [
    messages,
    setMessages
  ] = useState([]);

  return (
    <>
      <ProgressBar currentStep="1" />
      <div className="container-panel">
        <Card>
          <Formik
            initialValues={{
              preferred: 'email',
              contacts: [ Object.assign({}, fields) ]
            }}
            validate={values => {}}
            validationSchema={FormSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              clientLoad(normalizeData(values, location.id))
                .then(response => {
                  setMessages([]);
                  setSubmitting(false)
                  next(response.data.id);
                })
                .catch(error => {
                  let errors = [];

                  if (error.response.data.errors) {
                    errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title))
                  } else if (error.status) {
                    errors.push(error.status);
                  }

                  setMessages(errors.map(err => ({
                    type: 'error',
                    text: err
                  })));
                  setSubmitting(false)
                })
              }}
          >
            {({ values, handleChange, isSubmitting }) => (
              <Form>
                <FieldArray name="contacts" render={arrayHelpers => (
                  <>
                    {values.contacts.map((contact, index) => (
                    <div key={index} className="card--inner contact-information">
                      {index === 0 && messages.length > 0 && (
                        <Messages messages={messages} />
                      )}
                      {index > 0 ?
                        <div className="contact-add-info layout--right-2col contact-label">
                          <h2 className="header--weighted">{`Parent / Guardian ${index}`}</h2>
                          <Button theme="tertiary" onClick={() => arrayHelpers.remove(index)}><Icon icon="close" theme="secondary"/> Remove</Button>
                        </div>
                        :
                        <div className="contact-add-info layout--right-2col contact-label">
                          <h2 className="header--weighted">Contact Information</h2>
                        </div>
                      }
                      <div className="contact-add-info layout--even-2col">
                        <div className="field">
                          <FieldText id={`contacts.${index}.firstName`} label="First Name" required>
                            <Field type="text" id={`contacts.${index}.firstName`} name={`contacts.${index}.firstName`} />
                          </FieldText>
                          <ErrorMessage name={`contacts.${index}.firstName`} />
                        </div>
                        <div className="field">
                          <FieldText id={`contacts.${index}.lastName`} label="Last Name" required>
                            <Field type="text" id={`contacts.${index}.lastName`}  name={`contacts.${index}.lastName`} />
                          </FieldText>
                          <ErrorMessage name={`contacts.${index}.lastName`} />
                        </div>
                        <div className="field">
                          <FieldText id={`contacts.${index}.email`} label="Email Address" required>
                            <Field type="email" id={`contacts.${index}.email`} name={`contacts.${index}.email`} />
                          </FieldText>
                          <ErrorMessage name={`contacts.${index}.email`} />
                        </div>
                        <FieldPhone name={`contacts.${index}.phone`} label="Phone" />
                        <FieldRadios
                          id={`contacts.${index}.preferred`}
                          name={`contacts.${index}.preferred`}
                          value={values.contacts[index].preferred}
                          label="Preferred Contact Method"
                          layout="inline"
                          options={defaults.preferred.map(defaultPref => ({
                            label: defaultPref.label,
                            value: defaultPref.value
                          }))}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  ))}

                  {values.contacts.length < 3 && (
                    <div className="card--inner card--divider">
                      <Button theme="secondary" onClick={() => arrayHelpers.push(Object.assign({}, fields))}>
                        <Icon icon="plus" /> Add Parent/Guardian
                      </Button>
                    </div>
                  )}
                  </>
                )}
                />
                <div className="card--inner card--divider">
                  <div className="layout-add-submit">
                    <Button theme="primary" disabled={isSubmitting} type="submit">
                      <span className="button__label" disabled={isSubmitting}>Continue</span>
                      <Icon icon="forward" theme="primary" />
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </>
  )
}

const ClientAdd = () => (
<Page>
  <DashboardLayout>
    <ClientLayout>
      <ClientAddPage/>
    </ClientLayout>
  </DashboardLayout>
</Page>);

export default ClientAdd

import React, { Component } from 'react'
import Icon from '../icon'
import ProgressStep from './step'

const ProgressBar = ({ currentStep, client }) => {

  return (
    <nav aria-label="Breadcrumb" className="progress-bar">
      <ol className="progress-bar__list">
        <ProgressStep step={currentStep} stepIndex="1" title="Contact Information">
          <Icon icon="client" modifier="plus" />
        </ProgressStep>
        <ProgressStep step={currentStep} stepIndex="2" title="1st Appointment" url={client && `/client/${client}/edit/appt/1`}>
          <div className="appt-icon appt-icon-1">1</div>
        </ProgressStep>
        <ProgressStep step={currentStep} stepIndex="3" title="2nd Appointment" url={client && `/client/${client}/edit/appt/2`}>
          <div className="appt-icon appt-icon-2">2</div>
        </ProgressStep>
        <ProgressStep step={currentStep} stepIndex="4" title="3rd Appointment" url={client && `/client/${client}/edit/appt/3`}>
          <div className="appt-icon appt-icon-3">3</div>
        </ProgressStep>
        <ProgressStep step={currentStep} stepIndex="5" title="Payment" url={client && `/client/${client}/edit/payment`}>
          <Icon icon="accounting" theme="secondary" />
        </ProgressStep>
        <ProgressStep step={currentStep} stepIndex="6" title="Complete" url={client && `/client/${client}/edit/complete`}>
          <Icon icon="envelope" modifier="next" theme="secondary" />
        </ProgressStep>
      </ol>
    </nav>
  )
}

export default ProgressBar
